/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import useLayout from "src/hooks/useLayout";
import { useNavigate } from "react-router-dom";
import {
  iProduct,
  iProductCategoryPage,
  iProductProps,
} from "src/interfaces/products";
import productConsumer from "src/services/product";
import Product from "src/models/Product";
import { toast } from "react-toastify";
import { iPage } from "src/interfaces/layout";
import attributesConsumer from "src/services/Attributes";

export interface FormValues {
  atributosProdutoId: string;
  valor: string;
  produtoId: string;
}

const ProductContext = createContext<iProductProps>({} as iProductProps);

export function ProductProvider({ children }: { children: any }) {
  const router = useNavigate();

  const { disableButtons, setDisableButtons, setLoadingDialog } = useLayout();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGroupCategory, setLoadingGroupCategory] =
    useState<boolean>(false);
  const [products, setProducts] = useState<Array<iProduct>>([]);
  const [productsToSearch, setProductsToSearch] = useState<Array<any>>([]);
  const [productsForCategory, setProductsForCategory] = useState<Array<any>>(
    []
  );
  const [orderBy, setOrderBy] = useState<any>({ number: 1, order: false });
  const [orderByGroupCategory, setOrderByGroupCategory] = useState<any>({
    number: 1,
    order: false,
  });
  const [page, setPage] = useState<iProductCategoryPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });
  const [pageGroupCategory, setPageGroupCategory] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [productSelect, setProductSelect] = useState<iProduct | null>(null);
  const [code, setCode] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [ncm, setNcm] = useState<string>("");
  const [ean, setEan] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);
  const [clickButtonClean, setClickButtonClean] = useState<boolean>(false);
  const [codeManufacturer, setCodeManufacturer] = useState<string>("");
  const [unitsId, setUnitsId] = useState<string>("select");
  const [categoryProduct, setCategoryProduct] = useState<any>(null);
  const [attributeProduct, setAttributeProduct] = useState<FormValues | null>(
    null
  );

  const [errorCategory, setErrorCategory] = useState<boolean>(false);

  const [codeCategoryFilter, setCodeCategoryFilter] = useState<string>("");
  const [descriptionCategoryFilter, setDescriptionCategoryFilter] =
    useState<string>("");

  const [codeSearch, setCodeSearch] = useState<string>("");
  const [descriptionSearch, setDescriptionSearch] = useState<string>("");
  const [activeSearch, setActiveSearch] = useState<string>("select");
  const [itemSections, setItemSections] = useState<FormValues[]>([]);

  const [valueTabScreen, setValueTabScreen] = useState(0);

  const handleChangeTabScreen = (_: React.SyntheticEvent, newValue: number) => {
    setValueTabScreen(newValue);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangePageGroupCategory = (_: unknown, newPage: number) => {
    setPageGroupCategory({ ...pageGroupCategory, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value),
    });
  };

  const handleChangeRowsPerPageGroupCategory = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageGroupCategory({
      ...pageGroupCategory,
      page: 0,
      rowsPerPage: parseInt(event.target.value),
    });
  };

  const getMoreProductsToSearch = async (page: iPage) => {
    try {
      setLoading(true);

      const response = await productConsumer.get(page);

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) => {
        return {
          id: item?.id,
          code: item?.codigoProdutoEmpresa,
          label: item?.descricao,
        };
      });

      setProductsToSearch([...productsToSearch, ...data]);
    } catch {
      setProductsToSearch([...productsToSearch]);
    } finally {
      setLoading(false);
    }
  };

  const getProducts = async (
    _?: boolean,
    descriptionFilter: string = "",
    numberPerPage?: number
  ) => {
    setLoading(true);

    if (!numberPerPage) numberPerPage = 0;

    try {
      let filterText: any = descriptionSearch;

      if (!descriptionSearch) filterText = descriptionFilter;

      const response = await productConsumer.get(
        {
          ...page,
          rowsPerPage: page.rowsPerPage + numberPerPage,
        },
        codeSearch.trim(),
        filterText.trim(),
        activeSearch,
        orderBy,
        codeCategoryFilter.trim(),
        descriptionCategoryFilter.trim()
      );

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        Product.adapterToClass(item)
      );

      if (response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
          change: false,
        });
      }

      setProducts(data);
      setProductsToSearch(
        response.data.items.map((item: any) => {
          return {
            ...item,
            id: item.id,
            code: item.codigo,
            label: item.descricao,
          };
        })
      );
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar os produtos cadastrados!"
      );
    } finally {
      setLoading(false);
      setLoadingDialog(false);
    }
  };

  const cleanFilters = () => {
    setCodeSearch("");
    setDescriptionSearch("");
    setActiveSearch("");
    setCodeCategoryFilter("");
    setDescriptionCategoryFilter("");
  };

  const getProductsForCategory = async () => {
    setLoading(true);

    try {
      const response = await productConsumer.getProductsOrderByCategories(
        pageGroupCategory,
        codeSearch,
        descriptionSearch,
        activeSearch,
        orderByGroupCategory,
        codeCategoryFilter,
        descriptionCategoryFilter
      );

      if (response.status !== 200) throw response;

      if (response.data.totalItems > 0) {
        setPageGroupCategory({
          ...pageGroupCategory,
          total: response.data.totalItems,
        });
      }

      const data = response.data.items.map((item: any) => {
        return {
          ...item,
          produtos: item.produtos.map((produto: any) =>
            Product.adapterToClass(produto)
          ),
        };
      });

      setProductsForCategory(data);

      setTimeout(() => {
        setLoading(false);
        setLoadingDialog(false);
      }, 5000);
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar os produtos cadastrados!"
      );
      setLoading(false);
      setLoadingDialog(false);
    }
  };

  const handleSalveNewProduct = async () => {
    setErrorCategory(false);

    if (!categoryProduct) {
      setErrorCategory(true);
      return toast.warning("Favor selecione a categoria!");
    }

    try {
      setLoading(true);

      const product = new Product(
        null,
        code,
        description,
        categoryProduct?.id,
        "",
        unitsId,
        codeManufacturer,
        ncm,
        active,
        ean,
        []
      );

      const response = await productConsumer.created(product);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Produto cadastrado com sucesso!");
      product.id = response.data.id;

      await savedAttributes({ productId: product.id });

      handleSelected(null);
      handleSelectedCheckBox(null);

      setCode("");
      setDescription("");

      router(-1);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... tivemos um problema ao cadastrar o novo produto!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProduct = async () => {
    try {
      setLoading(true);

      const product = new Product(
        productSelect?.id,
        code,
        description,
        categoryProduct?.id,
        "",
        unitsId,
        codeManufacturer,
        ncm,
        active,
        ean,
        []
      );

      const response = await productConsumer.updated(product);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Produto alterado com sucesso!");

      await savedAttributesUpdate({ productId: product.id });

      handleSelected(null);
      handleSelectedCheckBox(null);

      setCode("");
      setDescription("");

      router(-1);
    } catch (e) {
      toast.error("Ops... tivemos um problema ao alterar o produto!");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProduct = async (product: iProduct) => {
    try {
      setLoading(true);

      const response = await productConsumer.deleted(`${product?.id}`);

      if (response.status !== 200) throw response;

      toast.success("Produto deletado com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      setPageGroupCategory({
        ...pageGroupCategory,
        page: 0,
      });

      handleSelected(null);
      handleSelectedCheckBox(null);

      await getProducts(true);
    } catch {
      toast.error("Erro ao deletar o produto!");
    } finally {
      setLoading(false);
    }
  };

  const handleActive = async () => {
    try {
      setLoading(true);

      const body = {
        ...productSelect,
        active: true,
      };

      const response = await productConsumer.updated(body as iProduct);

      if (response.status !== 200) throw response;

      toast.success("Produto ativado com sucesso!");
      handleSelected(null);
      handleSelectedCheckBox(null);
      getProducts(true);
    } catch {
      toast.error("Erro ao ativar o produto!");
    } finally {
      setLoading(false);
      setDisableButtons(false);
    }
  };

  const handleDisabled = async () => {
    try {
      setLoading(true);

      const body = {
        ...productSelect,
        active: false,
      };

      const response = await productConsumer.updated(body as iProduct);

      if (response.status !== 200) throw response;

      toast.success("Produto inativado com sucesso!");
      handleSelected(null);
      handleSelectedCheckBox(null);
      getProducts(true);
    } catch {
      toast.error("Erro ao inativado o produto!");
    } finally {
      setLoading(false);
      setDisableButtons(false);
    }
  };

  const handleSelected = (product: iProduct | null) => {
    setProductSelect(product);
    setCodeCategoryFilter("");
    setDescriptionCategoryFilter("");
    setCode(product?.code ?? "");
    setDescription(product?.description ?? "");
    setNcm(product?.ncm ?? "");
    setActive(product?.active ?? false);
    setCodeManufacturer(product?.codeManufacturer ?? "");
    setUnitsId(product?.unitsId ?? "select");
    setCategoryProduct({
      id: product?.categoryProductId,
      label: product?.categoryProduct,
      code: product?.code ?? "",
    });
    setEan(product?.ean ?? "");

    if (product && product?.atributos?.length > 0) {
      const att = product?.atributos.map((e: any) => {
        return {
          atributosProdutoId: e.atributosProdutoId,
          valor: e?.produtosAtributosValor,
          produtoId: product?.id,
        };
      });
      setItemSections(att as FormValues[]);
    }
  };

  const handleSelectedCheckBox = (product: iProduct | null) => {
    if (product?.id === productSelect?.id) {
      setDisableButtons(true);
      return setProductSelect(null);
    }

    setDisableButtons(false);
    setProductSelect(product);
  };

  const handleSortData = (data: { id: string; order: string }) => {
    const { id, order } = data;

    // ASC: < & >
    // DESC: > & <
    if (order === "asc") {
      const dataOrder = products.sort(function (a: any, b: any) {
        if (a[id] < b[id]) {
          return -1;
        }
        if (a[id] > b[id]) {
          return 1;
        }
        return 0;
      });

      setProducts([...dataOrder]);
    } else {
      const dataOrder = products.sort(function (a: any, b: any) {
        if (a[id] > b[id]) {
          return -1;
        }
        if (a[id] < b[id]) {
          return 1;
        }
        return 0;
      });

      setProducts([...dataOrder]);
    }
  };

  const handleAddSection = (section: string) =>
    setItemSections([...itemSections, {} as FormValues]);

  const handleRemoveSection = (index: number, section: string) =>
    setItemSections(itemSections.filter((_, i) => i !== index));

  const handleInputChange = (event: any, index: number, section: string) => {
    let { name, value } = event.target;

    const updateSections = (sections: FormValues[]) => {
      const updatedSections = [...sections];
      updatedSections[index] = { ...updatedSections[index], [name]: value };
      return updatedSections;
    };

    setItemSections(updateSections(itemSections));
  };

  const savedAttributes = async ({
    productId,
  }: {
    productId?: string | null | undefined;
  }) => {
    setLoading(true);

    try {
      const body = {
        produtosAtributos: itemSections.map((e: any) => {
          return {
            ...e,
            produtoId: productId,
            atributosProdutoId: e.atributosProdutoId,
          };
        }),
      };

      const response = await attributesConsumer.post(body);

      if (response.status !== 200) throw response;

      toast.success("Atributos salvos com sucesso!");
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? "Erro ao salvar os atributos do produto!"
      );
    } finally {
      setLoading(false);
    }
  };

  const savedAttributesUpdate = async ({
    productId,
  }: {
    productId?: string | null | undefined;
  }) => {
    setLoading(true);

    try {
      const body = {
        produtosAtributos: itemSections.map((e: any) => {
          return {
            ...e,
            produtoId: productId,
            atributosProdutoId: e.atributosProdutoId,
          };
        }),
      };

      const response = await attributesConsumer.put(body);

      if (response.status !== 200) throw response;

      toast.success("Atributos salvos com sucesso!");
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? "Erro ao salvar os atributos do produto!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      pageGroupCategory,
      getProducts,
      loading,
      setLoading,
      loadingGroupCategory,
      setLoadingGroupCategory,
      products,
      setProducts,
      handleChangePage,
      handleChangePageGroupCategory,
      handleChangeRowsPerPage,
      handleChangeRowsPerPageGroupCategory,
      handleSelected,
      code,
      setCode,
      description,
      setDescription,
      codeSearch,
      setCodeSearch,
      descriptionSearch,
      setDescriptionSearch,
      ncm,
      setNcm,
      codeManufacturer,
      setCodeManufacturer,
      unitsId,
      setUnitsId,
      categoryProduct,
      setCategoryProduct,
      active,
      setActive,
      productSelect,
      setProductSelect,
      handleSalveNewProduct,
      handleUpdateProduct,
      handleDeleteProduct,
      errorCategory,
      setErrorCategory,
      disableButtons,
      handleSelectedCheckBox,
      activeSearch,
      setActiveSearch,
      handleActive,
      handleDisabled,
      productsToSearch,
      ean,
      setEan,
      handleSortData,
      getMoreProductsToSearch,
      setPage,
      setPageGroupCategory,
      orderBy,
      setOrderBy,
      orderByGroupCategory,
      setOrderByGroupCategory,
      descriptionCategoryFilter,
      setDescriptionCategoryFilter,
      codeCategoryFilter,
      setCodeCategoryFilter,
      getProductsForCategory,
      productsForCategory,
      setProductsForCategory,
      valueTabScreen,
      setValueTabScreen,
      handleChangeTabScreen,
      clickButtonClean,
      setClickButtonClean,
      cleanFilters,
      itemSections,
      handleAddSection,
      handleRemoveSection,
      handleInputChange,
      attributeProduct,
      setAttributeProduct,
      savedAttributes,
      setProductsToSearch,
    };
  }, [
    page,
    pageGroupCategory,
    loading,
    loadingGroupCategory,
    descriptionCategoryFilter,
    codeCategoryFilter,
    products,
    code,
    description,
    codeSearch,
    descriptionSearch,
    ncm,
    ean,
    codeManufacturer,
    unitsId,
    categoryProduct,
    active,
    productSelect,
    errorCategory,
    disableButtons,
    activeSearch,
    productsToSearch,
    orderBy,
    orderByGroupCategory,
    productsForCategory,
    valueTabScreen,
    clickButtonClean,
    itemSections,
    attributeProduct,
  ]);

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
    </ProductContext.Provider>
  );
}

export default ProductContext;
