/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useMemo } from "react";
import { toast } from "react-toastify";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";
import ShoppingProcessDemandItemsConsumer from "src/services/shoppingProcessDemandItems";
import SolicitacaoCompraConsumer from "src/services/solicitacaoCompra";

const ConsolidateDemandContext = createContext<any>({} as any);

interface vinculateItem {
  produto: {
    id: string;
    codigo: string;
    descricao: string;
    atributos: [];
  } | null;
  solicitacaoCompra: any;
  descricaoDetalhada: string;
}

export function ConsolidateDemandProvider({ children }: { children: any }) {
  const { setDemands } = useShoppingProccessDemand();

  const [openRow, setOpenRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemVinculation, setItemVinculation] = useState<vinculateItem>({
    produto: null,
    solicitacaoCompra: null,
    descricaoDetalhada: "",
  });

  const handleRowClick = (rowIndex: any) => {
    setOpenRow(openRow === rowIndex ? null : rowIndex);
  };

  const save = async (processoCompraId: string) => {
    setLoading(true);

    try {
      // flow 1
      const bodyFlowOne = {
        processoCompraId: processoCompraId,
        produtoId: itemVinculation.produto?.id,
        descricaoDetalhada: itemVinculation.descricaoDetalhada,
        processoCompraDemandaItensAtributos: itemVinculation.produto?.atributos
          ?.filter((e: any) => e.selecionar)
          .map((e: any) => {
            return {
              atributosProdutoId: e?.atributosProdutoId,
              obrigatorio: e?.obrigatorio,
            };
          }),
      };
      const flowOne = await ShoppingProcessDemandItemsConsumer.created(
        bodyFlowOne
      );

      if (flowOne.status !== 200) throw flowOne;

      // flow 2
      const flowTwo = await SolicitacaoCompraConsumer.updated(
        itemVinculation.solicitacaoCompra
      );

      if (flowTwo.status !== 200) throw flowTwo;

      // flow 3
      const bodyFlowThree = {
        processoCompraDemandaItemId: flowOne.data.id,
        solicitacaoCompraId: itemVinculation.solicitacaoCompra.id,
      };
      const flowThree =
        await SolicitacaoCompraConsumer.createdPCDemandaItemSolicitacaoCompra(
          bodyFlowThree
        );

      if (flowThree.status !== 200) throw flowThree;

      toast.success("Alteração realizada com sucesso!");
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Desculpe, ocorreu um erro ao executar a alteração!"
      );
    } finally {
      setLoading(false);
    }
  };

  const remove = async (
    solicitacaoCompra: any,
    solicitacaoComprasFromDemand: any,
    demands: any,
    demandId: string
  ) => {
    setLoading(true);

    try {
      const flowOne = await SolicitacaoCompraConsumer.updated({
        solicitacaoCompra: [solicitacaoCompra],
      });

      if (flowOne.status !== 200) throw flowOne;

      const flowTwo =
        await SolicitacaoCompraConsumer.deletePCDemandaItemSolicitacaoCompra(
          solicitacaoCompra.id
        );

      if (flowTwo.status !== 200) throw flowTwo;

      const newSolicitacaoCompra = solicitacaoComprasFromDemand.filter(
        (e: any) => e.id !== solicitacaoCompra.id
      );

      const newDemands = demands.map((e: any) => {
        if (e.id === demandId) {
          return {
            ...e,
            solicitacaoCompras: newSolicitacaoCompra,
          };
        } else {
          return e;
        }
      });

      setDemands(newDemands);
      toast.success("Alteração realizada com sucesso!");
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Desculpe, ocorreu um erro ao executar a alteração!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      openRow,
      setOpenRow,
      openDialog,
      setOpenDialog,
      handleRowClick,
      itemVinculation,
      setItemVinculation,
      loading,
      setLoading,
      save,
      remove,
    };
  }, [openRow, openDialog, itemVinculation, loading]);

  return (
    <ConsolidateDemandContext.Provider value={contextValue}>
      {children}
    </ConsolidateDemandContext.Provider>
  );
}

export default ConsolidateDemandContext;
