/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControl,
  Autocomplete,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import useConsolidateDemand from "src/hooks/useConsolidateDemand";
import useProduct from "src/hooks/useProduct";
import CircularLoading from "src/components/Loading";
import { useEffect } from "react";
import Empty from "src/components/Empty";
import useShoppingProcess from "src/hooks/useShoppingProcess";

const SuggestedItemForm = () => {
  const { setOpenDialog, itemVinculation, setItemVinculation, loading, save } =
    useConsolidateDemand();

  const { shoppingProcesSelect } = useShoppingProcess();

  const {
    getProducts,
    productsToSearch,
    setCodeSearch,
    codeSearch,
    setDescriptionSearch,
    descriptionSearch,
  } = useProduct();

  useEffect(() => {
    if (codeSearch.length >= 4) {
      getProducts(false, "", 50);
    } else if (codeSearch.length === 0) {
      getProducts(false, "", 50);
    }
  }, [codeSearch]);

  useEffect(() => {
    if (descriptionSearch.length >= 4) {
      getProducts(false, "", 50);
    } else if (descriptionSearch.length === 0) {
      getProducts(false, "", 50);
    }
  }, [descriptionSearch]);

  const handleCheckboxChange = (index: number, field: string) => {
    setItemVinculation((prevItemVinculation: any) => {
      const updatedAttributes = prevItemVinculation.produto.atributos.map(
        (attr: any, i: number) => {
          if (i === index) {
            return {
              ...attr,
              [field]: !attr[field],
            };
          }
          return attr;
        }
      );

      return {
        ...prevItemVinculation,
        produto: {
          ...prevItemVinculation.produto,
          atributos: updatedAttributes,
        },
      };
    });
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">Adicionar Item Sugerido</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setItemVinculation({
            produto: null,
            solicitacaoCompra: null,
          });
          setOpenDialog(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {loading ? (
        <CircularLoading />
      ) : (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small">
                <Autocomplete
                  disablePortal
                  id="show_select_administrator_company"
                  options={productsToSearch}
                  value={itemVinculation?.produto ?? ""}
                  getOptionLabel={(option) =>
                    option && option.id ? `${option.codigo}` : ""
                  }
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setItemVinculation({
                        ...itemVinculation,
                        produto: {
                          id: value.id,
                          codigo: value.code,
                          descricao: value.label,
                          atributos: value.atributos
                            .filter((item: any, index: number, self: any) => {
                              const isFirstOccurrence =
                                self.findIndex(
                                  (obj: any) =>
                                    obj.atributosProdutoDescricao ===
                                    item.atributosProdutoDescricao
                                ) === index;
                              return isFirstOccurrence;
                            })
                            .map((item: any) => ({
                              ...item,
                              selecionar: false,
                              obrigatorio: false,
                            })),
                        },
                      });
                    } else {
                      setItemVinculation({
                        produto: null,
                        solicitacaoCompra: null,
                      });
                    }
                  }}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "clear") {
                      setItemVinculation({
                        produto: null,
                        solicitacaoCompra: null,
                      });
                      setCodeSearch("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecione o código: "
                      size="small"
                      onChange={(e) => setCodeSearch(e.target.value)}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small">
                <Autocomplete
                  disablePortal
                  id="show_select_administrator_company"
                  options={productsToSearch}
                  value={itemVinculation?.produto ?? ""}
                  getOptionLabel={(option) =>
                    option && option.id ? `${option.descricao}` : ""
                  }
                  onChange={(_: any, value: any) => {
                    if (value?.id) {
                      setItemVinculation({
                        ...itemVinculation,
                        produto: {
                          id: value.id,
                          codigo: value.code,
                          descricao: value.label,
                          atributos: value.atributos
                            .filter((item: any, index: number, self: any) => {
                              const isFirstOccurrence =
                                self.findIndex(
                                  (obj: any) =>
                                    obj.atributosProdutoDescricao ===
                                    item.atributosProdutoDescricao
                                ) === index;
                              return isFirstOccurrence;
                            })
                            .map((item: any) => ({
                              ...item,
                              selecionar: false,
                              obrigatorio: false,
                            })),
                        },
                      });
                    } else {
                      setItemVinculation({
                        produto: null,
                        solicitacaoCompra: null,
                      });
                    }
                  }}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "clear") {
                      setItemVinculation({
                        produto: null,
                        solicitacaoCompra: null,
                      });
                      setDescriptionSearch("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecione a descrição: "
                      size="small"
                      onChange={(e) => setDescriptionSearch(e.target.value)}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                value={itemVinculation.solicitacaoCompra?.codigoProduto ?? ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                value={
                  itemVinculation.solicitacaoCompra?.descricaoProduto ?? ""
                }
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descrição Detalhada"
                fullWidth
                multiline
                rows={4}
                size="small"
                value={itemVinculation.descricaoDetalhada}
                onChange={(e) =>
                  setItemVinculation({
                    ...itemVinculation,
                    descricaoDetalhada: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
            Atributos
          </Typography>
          {!itemVinculation?.produto ||
          itemVinculation?.produto?.atributos.length === 0 ? (
            <Empty />
          ) : (
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Conteúdo</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Obrigatório</TableCell>
                    <TableCell>Selecionar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemVinculation?.produto?.atributos.map(
                    (attr: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            {attr.atributosProdutoDescricao}
                          </TableCell>
                          <TableCell>
                            {attr.atributosProdutoDescricaoDetalhada}
                          </TableCell>
                          <TableCell>
                            {attr.atributosDadosTipoDescricao}
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={attr.selecionar}
                              onChange={() =>
                                handleCheckboxChange(index, "selecionar")
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={attr.obrigatorio}
                              onChange={() =>
                                handleCheckboxChange(index, "obrigatorio")
                              }
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ marginTop: 2 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<CloseIcon />}
              size="small"
              onClick={() => {
                setItemVinculation({
                  produto: null,
                  solicitacaoCompra: null,
                });
                setOpenDialog(false);
              }}
            >
              Fechar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              color="primary"
              size="small"
              onClick={() => save(shoppingProcesSelect?.id)}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default SuggestedItemForm;
